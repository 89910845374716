<template>
  <pro-menu-layout>
    <pro-deck :title="title" :cards="cards" v-slot="{ card }">
      <pro-deck-card v-bind="card" expandable :actions="action">
        <pro-folders :folders="card.folders" show-download-button />
      </pro-deck-card>
    </pro-deck>
  </pro-menu-layout>
</template>

<script>
import ProMenuLayout from "@/components/PROSmart/Layout/ProMenuLayout";
import ProDeck from "@/components/PROSmart/Deck/ProDeck";
import ProDeckCard from "@/components/PROSmart/Deck/ProDeckCard";
import ProFolders from "@/components/PROSmart/ProFolders";

export default {
  name: "ViewTendererVersion",
  components: { ProFolders, ProDeckCard, ProDeck, ProMenuLayout },
  data() {
    return {
      title: "Technical Folder - Tenderer Version",
      cards: [],
      action: [],
    };
  },
  async created() {
    this.$proSmart.setting
      .getModuleSetting(this, this.$route.params.id, "RecordOfInvitation")
      .then((b) => {
        if (b.assessmentVersionEnabled) {
          this.action.push({
            label: this.$t("Form.Field.UploadAssessmentVersion"),
            key: "Upload Assessment Version",
            to: {
              name: "DocumentViewUserForm",
              params: {
                mode: "Edit",
                code: "P_T_UploadAssessVer",
                userId: this.$route.params.userId,
              },
            },
          });
        }
      });

    let tendererRecord = (
      await this.$proSmart.formBuilder.getTechnicalTendererVersion(
        this,
        this.$route.params.id,
        this.$route.params.userId
      )
    ).tendererOpeningRecords;

    this.cards = tendererRecord.reduce(
      (items, { id, ref, name, technicalFolder }) => {
        let folder = technicalFolder.reduce(
          (folders, { fileId, fileName, size, md5, folderCategory }) => {
            return [
              ...folders,
              {
                fileId,
                fileName,
                fileSize: size,
                md5,
                folderDescription: folderCategory,
              },
            ];
          },
          []
        );

        return [
          ...items,
          {
            alias: ref,
            title: name,
            companyName: name,
            folders: folder,
            id,
          },
        ];
      },
      []
    );
  },
};
</script>
